<template>
  <div>
    <!-- 3333333 {{ openid }} -->
  </div>
</template>

<script>
import utlisApi from '@/utlis/utlis.js'
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
  name: 'Puzzle',
  data() {
    return {
      openid: '',
      option: {},
      utlisApi: new utlisApi()
    }
  },
  methods: {
  },
  mounted() {
    // this.openid = this.$route.params.openid;
    this.utlisApi.openid = this.$route.params.openid;
    // 自定义参数
    const myparams = {
      ai_kind_id:0
    }
    this.option = this.utlisApi.getParams(myparams);
    console.log(JSON.stringify(this.option), "打印一下option");
  //   const temp = {"app_id":"fbadd5065b7b446eac5fb52dd3381365",
  //     "expire_time":"1705480700987",
  //     "user_flag":"oR7KG65NQioduD950pGq44NdBbjw",
  //     "device_type":2,
  //     "ai_kind_id":0
  //     ,"sign":"D85CA81CDAF71BBA6664F5B93B93C503",} 
      // console.log(JSON.stringify(temp), "打印一下option11111");
      // console.log(temp, "打印一下option111");
    this.design = new CktDesign(this.optin);
    this.design.open();
  }
}
</script>

<style></style>
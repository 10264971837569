<template>
  <div id="app11" style="height: 100vh;">
    <!-- 1111 -->
  </div>
</template>

<script>
import { utlisApi } from "./text";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
  name: 'Poster',
  data() {
    return {
      openid: '',
      option: {},
      utlisApi: new utlisApi()
    }
  },
  methods: {
  },
  mounted() {
    // this.openid = this.$route.params.openid;
    this.utlisApi.openid = "1234";
    // 点击拉起
    const myparams = {
      // show_tabbar: 1,
      appId: 'fbadd5065b7b446eac5fb52dd3381365', // 企业appID
      timestamp: new Date().getTime(), // 过期时间
      container: "#app11",
      settingCode: "NLA7",
      userflag: "123456",
      // template_show_kinds: '502,491,494,493,29,157',
    }
    this.option = this.utlisApi.getParams(myparams);
    console.log(this.option, "打印一下option");
    this.design = new CktDesign.createTemplateCenter(this.option);
    this.design.open().then(() => {

      // 筛选项变更时触发---
      // @params { Iparams } params 筛选项
      this.design.onParamsChange((params) => {
        console.log({ params })
      })

      // 用户点击模板时触发---
      // @params { ITemplateItem } templateItem 模板项
      this.design.onChooseTemplate((templateItem) => {
        console.log( templateItem ,"用户点击了模板")
      })

      // 关闭模板中心
      // instance.close();

      // 动态调整筛选项
      // @params { IParams } params 筛选项
      // instance.setParams(params)
    })

  }

}
</script>

<style></style>
import md5 from 'js-md5'
class utlisApi {
    constructor() {
        this.openid = 0;
    }
    setopenid(openid){
        console.log(openid);
        this.openid = openid
    }
    // 签名函数
    getSign(params, key) {
        
        // console.log(params,key,"打印一下签名1111")
        let pArr = []
        for (let key in params) {
            pArr.push(`${key}=${params[key]}`)
        }
        console.log(pArr.sort().join('&'),key,"打印一下签名")
        return md5(pArr.sort().join('&') + key).toUpperCase()
        // return CryptoJS.MD5(pArr.sort().join('&') + key).toString().toUpperCase()
    }
    /**
     * 获取完成的parms
     * @param {*} myparms //传入个人定义模板的参数
     */
    getParams(myparms = {}) {
        const app_skey = '88F4A30966AED3A8352EE38DE6034F34'; // 企业appSkey
        let defaultParams = {
            app_id: 'fbadd5065b7b446eac5fb52dd3381365', // 企业appID
            expire_time: Date.now() + 600 * 1000, // 过期时间
            user_flag: this.openid, // 用户唯一标识
            device_type: 2, // pc: 1 mobile: 2
        }

        const mParams = {
            ...defaultParams,
            ...myparms
            // template_show_kinds: '1', //场景ID 多个使用,分割
            // redirect_url: encodeURIComponent(location.href), // 回调地址 根据需要自行更改即可
        }

        let params = Object.assign(defaultParams, mParams)
        // console.log(JSON.stringify({
        //     ...params,
        //     sign: this.getSign(params, app_skey)
        // }), "打印一下params")
        return {
            ...params,
            sign: this.getSign(params, app_skey)
        }
    }
}

export default utlisApi;
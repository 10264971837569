<template>
    <div>
      <!-- 3333333 {{ openid }} -->
    </div>
  </template>
  
  <script>
  import utlisApi from '@/utlis/utlis.js'
  //在需要使用的页面引入，并实例化对象
  import CktDesign from "@chuangkit/chuangkit-design";
  export default {
    name: 'BlankCanvas',
    data() {
      return {
        openid: '',
        option: {},
        utlisApi: new utlisApi()
      }
    },
    methods: {
    },
    mounted() {
      // this.openid = this.$route.params.openid;
      this.utlisApi.openid = this.$route.params.openid;
      // 自定义参数
      const myparams = {
        device_type: 2,//移动端
        kind_id: '166', // 场景ID
      }
      this.option = this.utlisApi.getParams(myparams);
      console.log(this.option, "打印一下option");
      this.design = new CktDesign(this.option);
      this.design.open();
    }
  }
  </script>
  
  <style></style>
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Home from './components/Home/index.vue'
import meAICenter from './views/me/AICenter/index.vue'
import meDesignEditor from './views/me/DesignEditor/index.vue'
import meDesignCenter from './views/me/DesignCenter/index.vue'
import testAICenter from './views/test/AICenter/index.vue'
import testDesignEditor from './views/test/DesignEditor/index.vue'
import testDesignCenter from './views/test/DesignCenter/index.vue'
import testposter from './views/test/poster/index.vue'
import testpuzzle from './views/test/puzzle/index.vue'
import pcDesignCenter from './views/test/pcDesignCenter/index.vue'
Vue.use(VueRouter)
//
const routes = [
  { path: '/Home', component: Home },
  { path: '/meAICenter/:openid/:userId', component: meAICenter },
  { path: '/meDesignEditor/:openid/:userId', component: meDesignEditor },
  { path: '/meDesignCenter/:openid/:userId', component: meDesignCenter },
  { path: '/testAICenter/:openid/:userId', component: testAICenter },
  { path: '/testDesignEditor/:openid/:userId', component: testDesignEditor },
  { path: '/testDesignCenter/:openid/:userId', component: testDesignCenter },
  { path: '/poster/:openid/:userId', component: testposter },
  { path: '/puzzle/:openid/:userId', component: testpuzzle },
  { path: '/', component: Home },
  { path: '/pcDesignCenter', component: pcDesignCenter },
  // { path: '/about', component: About }
]

const router = new VueRouter({
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import md5 from 'js-md5'

class utlisApi {
    constructor() {
        this.openid = 0;
    }
    setopenid(openid){
        console.log(openid);
        this.openid = openid
    }
    // 签名函数
    getSign(params) {
        let pArr = []
        for (let key in params) {
            pArr.push(`${key}=${params[key]}`)
        }
        console.log(pArr.sort().join('&'), "打印一下签名")
        return md5(pArr.sort().join('&')).toUpperCase()
    }
    /**
     * 获取完成的parms
     * @param {*} myparms //传入个人定义模板的参数
     */
    getParams(myparms = {}) {
        const app_skey = '88F4A30966AED3A8352EE38DE6034F34'; // 企业appSkey
        const defaultParams = {
            app_id: 'fbadd5065b7b446eac5fb52dd3381365', // 企业appID
            timestamp: new Date().getTime(), // 过期时间
            // user_flag: this.openid, // 用户唯一标识
            app_secret: app_skey,
            setting_code: 'NLA7',
        }

        const mParams = {
            // ...defaultParams,
            // device_type: 1,// pc: 1 mobile: 2
            // version: '2.0',
            // redirect_url: encodeURIComponent('http://localhost:8080/#/'), // 回调地址 根据需要自行更改即可
            ...myparms
        }

        let params = Object.assign(mParams)
        // console.log(JSON.stringify({
        //     ...params,
        //     sign: this.getSign(params, app_skey)
        // }), "打印一下params")
        console.log(123, defaultParams)
        console.log(456, {
            ...params,
            sign: this.getSign(defaultParams)
        })
        return {
            ...params,
            sign: this.getSign(defaultParams)
        }
    }

}

export {utlisApi};
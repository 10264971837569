<template>
  <div>
    <!-- <h2>我之前的写法</h2>
    <div class="category">
        <router-link to="/meDesignCenter/123">
            <button >模板设计中心</button>
        </router-link>
        <router-link to="/meDesignEditor/123">
            <button>模板编辑器</button>
        </router-link>
      <router-link to="/meAICenter/123">
            <button>ai设计中心</button>
      </router-link>
    </div>
    -->
    <h2>昨天按照要求修改</h2>
    <div class="category">
        <router-link to="/testDesignCenter/123/1">
            <button >模板设计中心</button>
        </router-link>
        <router-link to="/testDesignEditor/123/2">
            <button>模板编辑器</button>
        </router-link>
        <router-link to="/testAICenter/123/3">
                <button>ai设计中心</button>
        </router-link>
        <router-link to="/testPosterSpecialTopic/123/4">
                <button>海报专题页</button>
        </router-link>
    </div> 
    <!-- <router-link to="/component1">Go to Component1</router-link> -->
    <router-link to="/pcDesignCenter">跳转pc端模板中心</router-link>
  </div>
</template>

<script>
// import '@/utlis/uni.webview.0.1.52.js'
export default {
  mounted() {
    this.onShow();
  },
  methods: {
    onShow() {
      // console.log('onShow')
      // // 当返回当前界面，触发onShow事件
      // uni.postMessage({
      //     data: {
      //         action: '123'
      //     }
      // });
      // uni.navigateBack()

    }
  }
}
</script>

<style>

.category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
</style>
<template>
  <div>
  </div>
</template>

<script>
import utlisApi from '@/utlis/newutlis.js'
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
  name: 'Puzzle',
  data() {
    return {
      openid: '',
      option: {},
      utlisApi: new utlisApi()
    }
  },
  methods: {
  },
  mounted() {
    
    this.utlisApi.openid = this.$route.params.openid;
    // // 自定义参数
    let myparms = {
      ai_kind_id:0,//ai智能设计场景ID// 回调地址 根据需要自行更改即可
    }
    this.option = this.utlisApi.getParams(myparms);
    this.option['ai_kind_id'] = 0;
    
    this.design = new CktDesign(this.option);
    this.design.open();
  }
}
</script>

<style></style>
<template>
  <div>
    <!-- 1111 -->
  </div>
</template>

<script>
import utlisApi from '@/utlis/newutlis.js'
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
  name: 'Poster',
  data() {
      return {
          openid: '',
          option: {},
          utlisApi: new utlisApi()
      }
  },
  methods: {
  },
  mounted() {
      // this.openid = this.$route.params.openid;
      this.utlisApi.openid = this.$route.params.userId;
      // 点击拉起
      const myparams = {
          show_tabbar: 1,
          device_type: 2,
          template_show_kinds: '502,491,494,493,29,157',
      }
      this.option = this.utlisApi.getParams(myparams);
      // console.log(this.option, "打印一下option");

      this.design = new CktDesign(this.option);
      this.design.open();
  }
  
}
</script>

<style></style>